import styled from 'styled-components';
import config from 'data/config';
import useThemeVar from 'hooks/useThemeVar';

export const CalendarContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.25rem;

  svg.react-activity-calendar__calendar {
    overflow: visible;
  }

  .react-activity-calendar__count {
    color: ${() => useThemeVar(config.theme.primary, config.theme.secondary)};
  }
`;
