import React from 'react';
import { Container } from 'components/common';
import { Wrapper, Details, Thumbnail } from './styles';
import ContactForm from './ContactForm';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

export const Contact = () => {
  const { backgroundImage } = useStaticQuery(graphql`
    {
      backgroundImage: file(
        relativePath: { eq: "illustrations/man-running-from-plane.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
        }
      }
    }
  `);

  return (
    <Wrapper as={Container} id="contact">
      <Details>
        <h2>Contact Me</h2>
        <p>
          Please provider your name and a brief explanation of your inquiry,
          thanks!
        </p>
        <ContactForm />
      </Details>
      <Thumbnail>
        {/* <img src={contact} alt="I’m John and I’m a Backend & Devops engineer!" /> */}
        {/* <ManRunningSVG /> */}
        <GatsbyImage
          image={backgroundImage.childImageSharp.gatsbyImageData}
          alt="Image of man running away from plane"
        />
      </Thumbnail>
    </Wrapper>
  );
};
