import { FlexContainer } from 'components/common';
import { Statistic } from 'components/common/Statistic';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { BackgroundImage, Wrapper } from './styles';

export const GithubStats = ({ contributionsCollection }) => {
  const { backgroundImage } = useStaticQuery(graphql`
    {
      backgroundImage: file(
        relativePath: { eq: "illustrations/akira-city-lights.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
        }
      }
    }
  `);

  const {
    totalCommitContributions,
    totalIssueContributions,
    totalPullRequestContributions,
    totalPullRequestReviewContributions,
    totalRepositoryContributions,
    totalRepositoriesWithContributedCommits,
    totalRepositoriesWithContributedPullRequestReviews,
    totalRepositoriesWithContributedPullRequests,
  } = contributionsCollection;

  const totalsList = [
    {
      label: 'Total Contributions',
      value: totalCommitContributions,
      postfix: 'Commits',
    },
    {
      label: 'Issues Created',
      value: totalIssueContributions,
      postfix: '+',
    },
    {
      label: 'PRs Created',
      value: totalPullRequestContributions,
      postfix: '+',
    },
    {
      label: 'PRs Reviewed',
      value: `${totalPullRequestReviewContributions}`,
    },
    {
      label: 'Open Source Engagement',
      value: totalRepositoryContributions,
      postfix: 'Projects',
    },
    {
      label: 'Open Source Contributions',
      value: totalRepositoriesWithContributedCommits,
      postfix: 'Commits',
    },
    {
      label: 'Open Source PRs',
      value: totalRepositoriesWithContributedPullRequests,
    },
    {
      label: 'Open Source PRs Reviewed',
      value: totalRepositoriesWithContributedPullRequestReviews,
    },
  ];

  return (
    <Wrapper as={FlexContainer} gap="2.25rem">
      {totalsList.map((item, index) => (
        <Statistic fontSize="lg" key={`stat_${index}`}>
          <h4>{item.label}</h4>
          <p>
            {item.value}
            {item?.postfix && <small>{item.postfix}</small>}
          </p>
        </Statistic>
      ))}
      <BackgroundImage>
        <GatsbyImage
          image={backgroundImage.childImageSharp.gatsbyImageData}
          alt="Floral background graphic for github statistics"
        />
      </BackgroundImage>
    </Wrapper>
  );
};

export default GithubStats;
