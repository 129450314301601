import React from 'react';
import { Formik, Form, FastField, ErrorMessage } from 'formik';
import Recaptcha from 'react-google-recaptcha';
import { Button, Input } from 'components/common';
import { Error, Center, InputField } from './styles';
import { useTheme } from 'hooks/useTheme';
import useContactForm from 'hooks/useContactForm';

const ContactForm = () => {
  const { theme } = useTheme();
  const { initialValues, schema, submitForm } = useContactForm();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={submitForm}
    >
      {({ values, touched, errors, setFieldValue, isSubmitting }) => (
        <Form>
          <InputField theme={theme}>
            <Input
              as={FastField}
              type="text"
              name="name"
              component="input"
              aria-label="name"
              placeholder="Full name*"
              error={touched.name && errors.name}
            />
            <ErrorMessage component={Error} name="name" />
          </InputField>
          <InputField theme={theme}>
            <Input
              id="email"
              aria-label="email"
              component="input"
              as={FastField}
              type="email"
              name="email"
              placeholder="Email*"
              error={touched.email && errors.email}
            />
            <ErrorMessage component={Error} name="email" />
          </InputField>
          <InputField theme={theme}>
            <Input
              as={FastField}
              component="textarea"
              aria-label="message"
              id="message"
              rows="8"
              type="text"
              name="message"
              placeholder="Message*"
              error={touched.message && errors.message}
            />
            <ErrorMessage component={Error} name="message" />
          </InputField>
          {values.name &&
            values.email &&
            values.message &&
            process.env.NODE_ENV !== 'development' && (
              <InputField>
                <FastField
                  component={Recaptcha}
                  sitekey={process.env.GATSBY_PORTFOLIO_RECAPTCHA_KEY}
                  name="recaptcha"
                  onChange={(value) => setFieldValue('recaptcha', value)}
                />
                <ErrorMessage component={Error} name="recaptcha" />
              </InputField>
            )}
          {values.success && (
            <InputField>
              <Center>
                <h4>
                  Your message has been successfully sent, I will get back to
                  you ASAP!
                </h4>
              </Center>
            </InputField>
          )}
          <Center>
            <Button type="submit" disabled={isSubmitting} theme={theme}>
              Submit
            </Button>
          </Center>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
