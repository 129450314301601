import styled from 'styled-components';
import config from 'data/config';
import useThemeVar from 'hooks/useThemeVar';

// @media (max-width: 960px) {
//   grid-template-columns: repeat(2, 1fr);
// }

// @media (max-width: 680px) {
//   grid-template-columns: 1fr;
// }

export const Wrapper = styled.div`
  padding: 4rem 0;
`;

export const Details = styled.div`
  margin-bottom: 1.2rem;
  h2 {
    font-size: 26pt;
  }
`;

export const ThumbnailWrapper = styled.div`
  position: relative;
  align-self: start;
`;

export const Thumbnail = styled.div`
  border: 3px solid
    ${() => useThemeVar(config.theme.lightGray, config.theme.secondary)};
  border-radius: 50%;
  width: 156px;
  height: 156px;
  overflow: hidden;
  margin-right: 1.25rem;

  img {
    width: fit-content;
    margin: 0;
  }
`;

export const ThumbnailIcon = styled.div`
  width: 20px;
  height: 20px;
  padding: 0.25rem;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  border: 2px solid ${config.theme.lightGray};

  position: absolute;
  opacity: 0.85;

  &:hover {
    opacity: 1;
  }

  img {
    margin-bottom: 0;
  }
`;

export const GithubHireable = styled.div`
  bottom: 0.25rem;
  right: 1.7rem;

  background-color: ${(props) =>
    props.isHireable ? config.theme.success : config.theme.error};
`;

export const GithubStatus = styled.span`
  bottom: 2rem;
  right: 0.5rem;

  background-color: ${() =>
    useThemeVar(config.theme.primaryBg, config.theme.secondary)};

  .emoji {
    display: inline-block;
  }
`;
