import styled from 'styled-components';
import detailsIllustration from 'assets/illustrations/details.svg';
import config from 'data/config';
import useThemeVar from 'hooks/useThemeVar';

export const Wrapper = styled.div`
  position: relative;
`;

export const SkillsWrapper = styled.div`
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Details = styled.div`
  position: relative;
  flex: 0 auto;
  z-index: 0;
  width: 560px;
  padding-left: 2rem;

  @media (max-width: 960px) {
    padding-left: unset;
    width: 100%;
  }

  h2 {
    margin-bottom: 2rem;
    font-size: 26pt;
    color: ${() => useThemeVar(config.theme.darkBg, config.theme.white)};

    @media (max-width: 960px) {
      mix-blend-mode: ${() => useThemeVar('unset', 'difference')};
    }
  }

  p {
    margin-bottom: 1rem;
    font-size: 20pt;
    font-weight: normal;
    line-height: 1.3;
    color: ${() => useThemeVar(config.theme.gray, config.theme.lightGray)};

    @media (max-width: 960px) {
      mix-blend-mode: ${() => useThemeVar('unset', 'difference')};
    }
  }

  ul {
    margin-left: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    li {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      font-weight: bold;
    }
  }
`;

export const Thumbnail = styled.div`
  position: absolute;
  z-index: -1;
  max-width: 100%;
  max-height: 100vh;
  left: 0;
  top: -40%;

  background-color: ${() =>
    useThemeVar(config.theme.lightBg, config.theme.darkBg)};

  mask-image: url(${detailsIllustration});
  mask-size: contain;
  mask-position: left bottom;
  mask-repeat: no-repeat;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  div {
    width: 100%;
    position: relative;

    overflow: hidden;
    mix-blend-mode: ${() => useThemeVar('soft-light', 'color-burn')};
    opacity: 0.6;
  }

  img {
    left: -30%;
    margin-bottom: 0;
    width: 100%;
  }
`;
