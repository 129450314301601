import {
  // Button,
  Container,
} from 'components/common';
import QualityIcon from 'components/common/Icons/Quality';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { Details, SkillsWrapper, Thumbnail, Wrapper } from './styles';

export const Skills = () => {
  const { backgroundImage } = useStaticQuery(graphql`
    {
      backgroundImage: file(relativePath: { eq: "illustrations/floral.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, placeholder: TRACED_SVG, height: 640)
        }
      }
    }
  `);

  return (
    <Wrapper id="experience">
      <SkillsWrapper as={Container}>
        <Thumbnail>
          <div>
            <GatsbyImage
              image={backgroundImage.childImageSharp.gatsbyImageData}
              alt="Floral background graphic for experience section"
            />
          </div>
        </Thumbnail>
        <Details>
          <h2>
            15+ years of experience building web and interactive media
            applications.
          </h2>
          <p>
            In addition to my time spent as a full-stack engineer, I have been
            responsible for the following roles:
          </p>
          <ul>
            <li>
              <QualityIcon /> Team Lead
            </li>
            <li>
              <QualityIcon />
              Product Owner
            </li>
            <li>
              <QualityIcon />
              Project Manager
            </li>
            <li>
              <QualityIcon />
              Scrum Master
            </li>
          </ul>
          {/* 
          TODO: Add roles page.
          <Button as={AnchorLink} href="#contact">
            Learn More &raquo;
          </Button> 
          */}
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
