import config from 'data/config';
import ActivityCalendar from 'react-activity-calendar';
import { useTheme } from 'hooks/useTheme';
import ReactTooltip from 'react-tooltip';
import React from 'react';
import { CalendarContainer } from './styles';

function parseCalendarData(contributionsCollection) {
  const {
    contributionCalendar: { weeks },
  } = contributionsCollection;

  const CONTRIBUTION_LEVEL_MAP = {
    NONE: 0,
    FIRST_QUARTILE: 1,
    SECOND_QUARTILE: 2,
    THIRD_QUARTILE: 3,
    FOURTH_QUARTILE: 4,
  };

  // Reduce array of nested weeks into flat array of contributions
  return weeks.reduce((acc, week) => {
    return acc.concat(
      week.contributionDays.map((day) => {
        return {
          level: CONTRIBUTION_LEVEL_MAP[day.contributionLevel],
          date: day.date,
          count: day.contributionCount,
        };
      })
    );
  }, []);
}

const GithubActivityCalendar = ({ contributionsCollection = [] }) => {
  const { theme } = useTheme();

  const calendarData = parseCalendarData(contributionsCollection);

  return (
    <CalendarContainer theme={theme}>
      <ActivityCalendar
        blockMargin={5}
        blockRadius={7}
        blockSize={14}
        loading={!calendarData}
        data={calendarData}
        labels={{
          legend: {
            less: 'Less',
            more: 'More',
          },
          months: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
          totalCount:
            '{{count}} "I did a thing!" moments in {{year}} so far 🚀',
        }}
        color={
          theme === 'light' ? config.theme.primary : config.theme.secondary
        }
      >
        <ReactTooltip html />
      </ActivityCalendar>
    </CalendarContainer>
  );
};

export default GithubActivityCalendar;
