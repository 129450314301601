import styled from 'styled-components';
import config from 'data/config';

export const Error = styled.span`
  color: #ff4136;
`;

export const Center = styled.div`
  text-align: left;

  h4 {
    font-weight: normal;
  }
`;

export const InputField = styled.div`
  position: relative;
  margin-bottom: 1rem;

  input,
  textarea {
    border-color: ${({ theme }) =>
      theme !== 'dark'
        ? config.theme.primary
        : config.theme.secondary} !important;
  }
`;
