import styled from 'styled-components';
import config from 'data/config';
import useThemeVar from 'hooks/useThemeVar';

export const Wrapper = styled.div`
  position: relative;
  padding: 3.5rem 4rem 2.25rem 4rem;
  border-radius: 25% 75% 79% 21% / 26% 48% 52% 74%;
  background: ${() => useThemeVar(config.theme.lightBg, config.theme.darkBg)};
  overflow: hidden;
`;

export const BackgroundImage = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  width: 100%;
  opacity: 0.6;
  mix-blend-mode: ${() => useThemeVar('soft-light', 'color-burn')};

  @media (max-width: 960px) {
    width: 80%;
  }
`;
