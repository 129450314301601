import glassesIcon from 'assets/icons/eye-glasses.svg';
import { Container, FlexContainer } from 'components/common';
import { Statistic } from 'components/common/Statistic';
import useGithubUser from 'hooks/useGithubUser';
import { useTheme } from 'hooks/useTheme';
import React from 'react';
import GithubActivityCalendar from './components/GithubActivityCalendar';
import GithubStats from './components/GithubStats';
import {
  Details,
  GithubHireable,
  GithubStatus,
  Thumbnail,
  ThumbnailIcon,
  ThumbnailWrapper,
  Wrapper,
} from './styles';

export const Activity = () => {
  const { theme } = useTheme();
  const {
    name,
    company,
    avatarUrl,
    bio,
    isHireable,
    location,
    status,
    contributionsCollection,
  } = useGithubUser();

  return (
    <Wrapper as={Container} id="activity">
      <Details as={FlexContainer} wrap="no-wrap">
        <ThumbnailWrapper>
          <Thumbnail>
            <img className="thumbnail" src={avatarUrl} alt={name} />
          </Thumbnail>

          <GithubStatus
            as={ThumbnailIcon}
            data-tip={`
              <div class="tooltip-container">
                <small style="display:block; margin-bottom: 0.2rem;"><strong>Github Status</strong></small>
                <span>
                  ${status.message}
                </span>
              </div>
            `}
            dangerouslySetInnerHTML={{ __html: status.emojiHTML }}
          />

          <GithubHireable
            as={ThumbnailIcon}
            data-tip={`
              <div class="tooltip-container">
                <small style="display:block; margin-bottom: 0.2rem;"><strong>Work Status</strong></small>
                <span>
                  ${isHireable ? 'Available for Hire' : 'Unavailable to Hire'}
                </span>
              </div>
            `}
            isHireable={isHireable}
          >
            <img src={glassesIcon} alt="Indicator if I'm available to work" />
          </GithubHireable>
        </ThumbnailWrapper>
        <div>
          <h2>Github Activity</h2>
          {bio && (
            <Statistic>
              <h4>Bio</h4>
              <p style={{ textAlign: 'left' }}>{bio}</p>
            </Statistic>
          )}

          <FlexContainer gap="1rem">
            <Statistic theme={theme}>
              <h4>Location</h4>
              <p style={{ textAlign: 'left' }}>{location}</p>
            </Statistic>

            {company && (
              <Statistic>
                <h4>Company</h4>
                <p style={{ textAlign: 'left' }}>{company}</p>
              </Statistic>
            )}
          </FlexContainer>

          <GithubStats contributionsCollection={contributionsCollection} />
        </div>
      </Details>

      <GithubActivityCalendar
        contributionsCollection={contributionsCollection}
      />
    </Wrapper>
  );
};
