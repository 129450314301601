import { Container } from 'components/common';
import React from 'react';
import PinnedGithubRepos from './components/PinnedGithubRepos';
// import ThingsToPrint from './components/ThingsToPrint'
import { Wrapper } from './styles';

export const Projects = () => {
  return (
    <Wrapper as={Container} id="projects">
      <PinnedGithubRepos />
      {/* <ThingsToPrint /> */}
    </Wrapper>
  );
};
