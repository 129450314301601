import { Button, Container } from 'components/common';
import { Header } from 'components/theme';
import { useTheme } from 'hooks/useTheme';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {
  Details,
  IntroWrapper,
  Thumbnail,
  ThumbnailContainer,
  Wrapper,
} from './styles';

import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

export const Intro = ({ title, subtitle, image, button = false }) => {
  const { theme } = useTheme();

  const { defaultImg } = useStaticQuery(graphql`
    {
      defaultImg: file(relativePath: { eq: "illustrations/night-city.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
        }
      }
    }
  `);

  const introImg = image
    ? image
    : {
        image: defaultImg.childImageSharp.gatsbyImageData,
        alt: 'background image of night city',
      };

  return (
    <Wrapper>
      <Header />
      <IntroWrapper as={Container}>
        <Details theme={theme}>
          <h1>{title}</h1>
          <h4>{subtitle}</h4>

          {button && (
            <Button as={AnchorLink} href={button.href}>
              {button.text}
            </Button>
          )}
        </Details>
        <ThumbnailContainer theme={theme}>
          <Thumbnail theme={theme}>
            <GatsbyImage {...introImg} />
          </Thumbnail>
        </ThumbnailContainer>
      </IntroWrapper>
    </Wrapper>
  );
};

export default Intro;
