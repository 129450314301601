import { graphql, useStaticQuery } from 'gatsby';

const useGithubUser = () => {
  const {
    github: { viewer },
  } = useStaticQuery(
    graphql`
      {
        github {
          viewer {
            company
            avatarUrl
            bio
            email
            name
            isHireable
            location
            status {
              createdAt
              emoji
              emojiHTML
              message
            }
            contributionsCollection {
              startedAt
              totalCommitContributions
              totalIssueContributions
              totalPullRequestContributions
              totalPullRequestReviewContributions
              totalRepositoryContributions
              totalRepositoriesWithContributedCommits
              totalRepositoriesWithContributedPullRequestReviews
              totalRepositoriesWithContributedPullRequests
              contributionCalendar {
                totalContributions
                weeks {
                  contributionDays {
                    color
                    contributionCount
                    contributionLevel
                    date
                  }
                }
                colors
              }
              contributionYears
            }
          }
        }
      }
    `
  );

  return {
    ...viewer,
  };
};

export default useGithubUser;
