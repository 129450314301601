import { Card, Content, Grid, Item, TitleWrap } from 'components/common';
import Fork from 'components/common/Icons/Fork';
import Star from 'components/common/Icons/Star';
import usePinnedGithubRepos from 'hooks/usePinnedGithubRepos';
import { useTheme } from 'hooks/useTheme';
import React from 'react';
import { Languages, Stats, Wrapper } from './styles';

export const PinnedGithubRepos = () => {
  const { theme } = useTheme();
  const { nodes } = usePinnedGithubRepos();

  return (
    <Wrapper>
      <h2>Pinned Github Repos</h2>
      <Grid>
        {nodes.map(
          ({
            id,
            url,
            name,
            description,
            stargazers,
            languages,
            forkCount,
          }) => (
            <Item
              key={id}
              as="a"
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              theme={theme}
            >
              <Card theme={theme}>
                <Content>
                  <h4>{name}</h4>
                  <p>{description}</p>
                </Content>
                <TitleWrap>
                  <Stats theme={theme}>
                    <div>
                      <Star color={theme === 'light' ? '#000' : '#fff'} />
                      <span>{stargazers.totalCount}</span>
                    </div>
                    <div>
                      <Fork color={theme === 'light' ? '#000' : '#fff'} />
                      <span>{forkCount}</span>
                    </div>
                  </Stats>
                  <Stats theme={theme}>
                    <Languages>
                      {languages.nodes.map(({ id, name }) => (
                        <span key={id}>{name}</span>
                      ))}
                    </Languages>
                  </Stats>
                </TitleWrap>
              </Card>
            </Item>
          )
        )}
      </Grid>
    </Wrapper>
  );
};

export default PinnedGithubRepos;
