import { graphql, useStaticQuery } from 'gatsby';

export const usePinnedGithubRepos = () => {
  const {
    github: {
      viewer: {
        pinnedItems: { nodes },
      },
    },
  } = useStaticQuery(
    graphql`
      {
        github {
          viewer {
            pinnedItems(last: 8) {
              nodes {
                ... on GitHub_Repository {
                  id
                  name
                  url
                  description
                  forkCount
                  stargazers {
                    totalCount
                  }
                  languages(first: 3) {
                    nodes {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return {
    nodes,
  };
};

export default usePinnedGithubRepos;
