import overlayIllustration from 'assets/illustrations/overlay.svg';
import config from 'data/config';
import useThemeVar from 'hooks/useThemeVar';
import styled from 'styled-components';
// import overlayIllustration from "assets/illustrations/layered-steps.svg";

export const Wrapper = styled.div`
  padding-bottom: 4rem;
  overflow: hidden;
  position: relative;

  @media (max-width: 960px) {
    background-size: 68% auto;
    padding-bottom: 0;
  }
`;

export const IntroWrapper = styled.div`
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Details = styled.div`
  position: relative;
  z-index: 1;
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 36pt;
    color: ${() => useThemeVar(config.theme.darkBg, config.theme.secondary)};

    @media (max-width: 960px) {
      mix-blend-mode: ${() => useThemeVar('unset', 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 30pt;
    }
  }

  h4 {
    margin-bottom: 2.5rem;
    font-size: 32pt;
    font-weight: normal;
    color: ${({ theme }) =>
      theme === 'light' ? config.theme.gray : config.theme.headerDark};

    @media (max-width: 960px) {
      mix-blend-mode: ${() => useThemeVar('unset', 'difference')};
    }

    @media (max-width: 680px) {
      font-size: 26pt;
    }
  }
`;

export const ThumbnailContainer = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  width: 740px;
  min-height: 800px;
  padding-bottom: 4rem;

  mask-image: url(${overlayIllustration});
  mask-size: contain;
  overflow: hidden;
  mask-repeat: no-repeat;
  background: ${() => useThemeVar(config.theme.lightBg, config.theme.darkBg)};
`;

export const Thumbnail = styled.div`
  padding-right: 2rem;
  opacity: 0.6;
  mix-blend-mode: ${() => useThemeVar('soft-light', 'soft-light')};

  min-width: 100%;
  min-height: 100vh;

  @media (max-width: 960px) {
    width: 80%;
  }

  svg {
    max-width: 100%;
  }
`;
