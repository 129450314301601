import axios from 'axios';
import * as Yup from 'yup';
import { url } from 'data/config';

export const useContactForm = () => {
  const initialValues = {
    name: '',
    email: '',
    message: '',
    recaptcha: '',
    success: false,
  };

  const schema = Yup.object().shape({
    name: Yup.string().required('Full name field is required'),
    email: Yup.string()
      .email('Invalid email')
      .required('Email field is required'),
    message: Yup.string().required('Message field is required'),
    recaptcha:
      process.env.NODE_ENV !== 'development'
        ? Yup.string().required('Robots are not welcome yet!')
        : Yup.string(),
  });

  const submitForm = async (
    { name, email, message },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      await axios({
        method: 'POST',
        url:
          process.env.NODE_ENV !== 'development'
            ? `${url}/api/contact`
            : 'http://localhost:3000/api/contact',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          name,
          email,
          message,
        }),
      });
      setSubmitting(false);
      setFieldValue('success', true);
      setTimeout(() => resetForm(), 6000);
    } catch (err) {
      setSubmitting(false);
      setFieldValue('success', false);
      alert('Something went wrong, please try again!');
    }
  };

  return {
    initialValues,
    schema,
    submitForm,
  };
};

export default useContactForm;
